import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

import QuickLinks from "../../components/quickLinks"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"

class Implementation extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Education Services"}
          titleDesc={
            "Want to Transform your Education with AI-Enabled Digital Infrastructure? Our experts will guide you to build a strategic plan for implementing the platform."
          }
          titleKeywords={
            "fuse classroom, online classes, online classroom, live classes, online school classes, transform education, online education platform, online learning platform, ai enabled education platform, e learning platforms"
          }
          fbDes={"Work directly with our experts to build a strategic plan to implement an AI-enabled platform."}
          twitDesc={"Work directly with our experts to build a strategic plan to implement an AI-enabled platform."}
          seoLink={"implementation/"}
        />

        {/* top section  */}
        <section role="top--section" className="implementation position-relative mb-lg-5">
          {/* content */}
          <article className="container h-100">
            <Row className="align-items-center justify-content-center pb-5 h-100">
              <Col lg="10" className="text-center text-white">
                <div className="mb-5">
                  <h1 className="font-weight-bold text-white">
                    Transforming Education with <br />
                    AI-Enabled Digital Infrastructure
                  </h1>
                  <div className="h3 mt-4 font-weight-semibold">Innovation Beyond the Classroom.</div>

                  <div className="mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-warning mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Implementation_Top_Request_Demo")}
                      state={{ page: "implementation" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              {/* quick navigation */}
              <Col xl={8} lg={10} className="quick-links d-none d-lg-flex">
                <QuickLinks />
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Build implementation plan */}
        <section className="py-5 bg-80 right mt-lg-12" id="implement">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-4">
                <img
                  src="/images/implementation/implementation.webp"
                  alt="Implementation"
                  className="img-fluid shadow-lg img-rounded"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="py-4">
                  <h2 className="font-weight-bold">Build an Implementation Plan</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Our specialists work directly with Principals, Administrators, Faculty, and Students to build a
                    strategic plan for implementing the platform.
                  </div>
                  <Link
                    className="btn btn-success font-weight-semibold text-uppercase mt-4"
                    to="/request-for-more-info/"
                    // onClick={() => FormEventAnalytics("Implementation_Plan_Request_Demo")}
                    state={{ page: "implementation" }}
                    id="speak-with-expert__implementation-plan"
                  >
                    speak with an expert
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Build Implementation plan End */}

        {/* Conduct training */}
        <section className="py-5" id="training">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/implementation/training.webp"
                  alt="Training"
                  className="img-fluid shadow-lg img-rounded"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-4">
                  <h2 className="font-weight-bold">Conduct Training</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    We help to develop a curated training program specifically designed to address the needs of
                    administrators, faculty, and students. In this phase, we help Colleges and Universities successfully
                    adopt the Fuse Classroom platform.
                  </div>
                  <Link
                    className="btn btn-success font-weight-semibold text-uppercase mt-4"
                    to="/request-for-more-info/"
                    // onClick={() => FormEventAnalytics("Implementation_Training_Request_Demo")}
                    state={{ page: "implementation" }}
                    id="speak-with-expert__training"
                  >
                    speak with an expert
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Conduct training End */}

        {/* Ensure successful adoption */}
        <section className="py-5 bg-80 right" id="adoption">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-5">
                <img
                  src="/images/implementation/adoption.webp"
                  alt="Adoption"
                  className="img-fluid shadow-lg img-rounded"
                  width="500"
                  height="auto"
                />
              </Col>

              <Col md={{ span: 5, order: 1 }}>
                <div className="py-4">
                  <h2 className="font-weight-bold">Ensure Successful Adoption</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    To provide consistent support to our College and University partners, our specialists ensure the
                    highest quality online experience, even as needs change and evolve.
                  </div>
                  <Link
                    className="btn btn-success font-weight-semibold text-uppercase mt-4"
                    to="/request-for-more-info/"
                    // onClick={() => FormEventAnalytics("Implementation_Adoption_Request_Demo")}
                    state={{ page: "implementation" }}
                    id="speak-with-expert__adoption"
                  >
                    speak with an expert
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Ensure successful adoption End */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in becoming partner?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("Implementation_Bottom_Request_Demo")}
                    state={{ page: "implementation" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default Implementation
