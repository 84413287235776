import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

class QuickLinks extends React.Component {
  render() {
    return (
      <div className="container w-100 d-flex justify-content-center">
        <Row>
          <Col lg={4}>
            <button onClick={() => scrollTo("#implement")} className="h-100 w-100">
              <Card>
                <Card.Img
                  variant="top"
                  src="/images/implementation/mini-implementation.png"
                  alt="Mini Implementation"
                  width="298"
                  height="auto"
                />
                <Card.Body>
                  <Card.Title>Build an Implementation Plan</Card.Title>
                </Card.Body>
                <Card.Footer>
                  <img src="/images/implementation/chevron-down.svg" className="img-fluid" width="13" height="auto" />
                </Card.Footer>
              </Card>
            </button>
          </Col>
          <Col lg={4}>
            <button onClick={() => scrollTo("#training")} className="h-100 w-100">
              <Card>
                <Card.Img
                  variant="top"
                  src="/images/implementation/mini-training.png"
                  alt="Mini Training"
                  width="298"
                  height="auto"
                />
                <Card.Body>
                  <Card.Title>Conduct Training</Card.Title>
                </Card.Body>
                <Card.Footer>
                  <img src="/images/implementation/chevron-down.svg" className="img-fluid" width="13" height="auto" />
                </Card.Footer>
              </Card>
            </button>
          </Col>
          <Col lg={4}>
            <button onClick={() => scrollTo("#adoption")} className="h-100 w-100">
              <Card>
                <Card.Img
                  variant="top"
                  src="/images/implementation/mini-adoption.png"
                  alt="Mini Adoption"
                  width="298"
                  height="auto"
                />
                <Card.Body>
                  <Card.Title>Ensure Successful Adoption</Card.Title>
                </Card.Body>
                <Card.Footer>
                  <img src="/images/implementation/chevron-down.svg" className="img-fluid" width="13" height="auto" />
                </Card.Footer>
              </Card>
            </button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default QuickLinks
